body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

video.snapshot-generator {
  display: none;
}
.loding_spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
textarea {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 8.5px 14px;
  border-radius: 5px;
  width: 100%;
  border-color: rgb(196, 196, 196);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

strong {
  font-weight: 600 !important;
}

em {
  font-style: italic !important;
}

.ql-editor {
  height: 200px !important;
}

.mt {
  margin-top: 0px !important;
}

.parentCheckbox .MuiFormControlLabel-label {
  font-weight: 500;
  font-size: 18px;
}

/* .cropper-crop-box {
  height: 128px !important;
  width: 128px !important;
} */
/* .cropper-canvas{
  min-height: 128px !important;
  min-width: 128px !important;
} */
/* .cropper-container {
  max-height: 500px;
  max-width: 570px;
}
.cropper-canvas {
  max-height: 500px;
  max-width: 570px;
} */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* initial resets */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-family: 'Roboto';
}

/* ul {
  list-style: none;
} */

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  border: none;
}

a,
button {
  cursor: pointer;
}

a {
  color: #000;
}

/* default Class Defined */
.text-center {
  text-align: center;
}

p.MuiFormHelperText-root {
  margin-left: 0px;
}

/* ul,
li {
  list-style: none;
} */

/* Table design */
table {
  position: relative;
  border: 1px solid rgb(70 123 121 / 50%);
}

table thead tr th.MuiTableCell-head {
  color: #7cfbd9 !important;
  background-color: #2b2b2b !important;
  border-bottom: 1px solid #2b2b2b !important;
  white-space: nowrap !important;
  padding: 16px 15px !important;
}

table thead tr th.MuiTableCell-head .MuiCheckbox-root {
  color: #fff !important;
}

table tbody {
  position: relative;
}

table .MuiTableCell-body {
  padding: 8px 15px;
  white-space: nowrap;
  /* border-bottom: 1px solid rgb(31 90 88); */
  border-bottom: 1px solid rgb(70 123 121 / 50%);
  /* border-bottom: 1px solid #7CFBD9; */
}

/* table tbody tr.MuiTableRow-root {
  vertical-align: unset;
} */
table tbody tr:nth-of-type(odd) td {
  background-color: #f7f7f7;
}

thead.MuiTableHead-root {
  background-color: #2b2b2b !important;
}

.tableheadarrow {
  display: flex;
  flex-direction: column;
  flex: 0 0 18px;
  align-items: center;
  justify-content: space-between;
  height: 14px;
  margin-left: 2px;
}
.tableheadarrow .active img {
  filter: invert(1);
}

.tableheadarrow img {
  filter: invert(56%) sepia(3%) saturate(8%) hue-rotate(335deg) brightness(90%) contrast(89%);
  cursor: pointer;
  height: 5px;
}

.loader-table {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.MuiSelect-select span {
  color: rgb(0, 0, 0, 0.42);
}

.MuiButtonBase-root {
  text-transform: none;
}

.MuiTabs-root button {
  font-weight: 600;
  font-size: 16px;
}

.breadcrumbs {
  position: relative;
}

.flex_row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.flex_col-3 {
  max-width: 25%;
  flex: 0 0 25%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.flex_col-4 {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.flex_col-6 {
  max-width: 50%;
  flex: 0 0 50%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.MuiCircularProgress-colorPrimary {
  color: #2b2b2b !important;
}

.MuiTable-root .MuiCheckbox-root.Mui-disabled svg {
  width: 18px;
  height: 18px;
  background-color: rgb(0, 0, 0, 0.26);
  color: rgb(0, 0, 0, 0.26);
  margin-left: 3px;
  /* border-radius: 3px; */
}

.MuiTable-root .MuiCheckbox-root.Mui-disabled svg path {
  fill: transparent;
}

.showDisabled {
  color: rgb(0 0 0 / 50%) !important;
  box-shadow: none;
  background-color: rgb(0 0 0 / 12%);
}

.showDisabled .MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #000 !important;
}

.MuiButton-containedPrimary {
  color: #7cfbd9 !important;
}

/* .MuiLink-root {
  color: #3f26eb !important;
} */

.MuiButton-containedPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.mt37 {
  margin-top: 37px;
}

.margin-0 {
  margin: 0px !important;
}

/* Page Not Found */
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  animation: colorSlide 15s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}

.flex-container .text-center {
  text-align: center;
}

.flex-container .text-center h1,
.flex-container .text-center h3 {
  margin: 10px;
  cursor: default;
}

.flex-container .text-center h1 .fade-in,
.flex-container .text-center h3 .fade-in {
  animation: fadeIn 2s ease infinite;
}

.flex-container .text-center h1 {
  font-size: 8em;
  transition: font-size 200ms ease-in-out;
  border-bottom: 1px dashed white;
}

.flex-container .text-center h1 span#digit1 {
  animation-delay: 200ms;
}

.flex-container .text-center h1 span#digit2 {
  animation-delay: 300ms;
}

.flex-container .text-center h1 span#digit3 {
  animation-delay: 400ms;
}

.flex-container .text-center button {
  border: 1px solid white;
  background: transparent;
  outline: none;
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  transition: background-color 200ms ease-in;
  margin: 20px 0;
}

.flex-container .text-center button:hover {
  background-color: white;
  color: #555;
  cursor: pointer;
}

@keyframes colorSlide {
  0% {
    background-color: #152a68;
  }

  25% {
    background-color: royalblue;
  }

  50% {
    background-color: seagreen;
  }

  75% {
    background-color: tomato;
  }

  100% {
    background-color: #152a68;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
